import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router";

import { PageHeader, Spinner } from "@dzambalaorg/mui-page-layout";
import { RichTextDisplay } from "@dzambalaorg/mui-rte";

import { StyledCurrentPageHeader, StyledCurrentRoot, StyledSpinnerBox } from "../../../../../shared";
import { ButtonBack } from "../../../../../shared/assets/svg/button-back";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";
import { pageHeaderStyles, StyledDescription, StyledHeaderBox, StyledImage } from "./styled";
import { CurrentBoxContent } from "./content";
import { useFetchBox } from "./useFetchBox";
import { Timeline } from "./timeline";
import { VestingBoxPurchaseButton } from "../../../../../components/buttons/purchase";

export const CurrentVestingBox = () => {
  const { selected, isLoading } = useFetchBox();
  const navigate = useNavigate();

  const goBackHandler = () => navigate(-1);

  if (isLoading) {
    return (
      <StyledSpinnerBox>
        <Spinner />
      </StyledSpinnerBox>
    );
  }

  if (!selected) {
    // TODO - add error component
    return <div></div>;
  }

  return (
    <StyledCurrentRoot>
      <Breadcrumbs paths={["vesting.boxes", "vesting.currentBox"]} data={[{}, selected]} />

      <StyledCurrentPageHeader>
        <IconButton onClick={goBackHandler}>
          <ButtonBack />
        </IconButton>
        <PageHeader message="pages.vesting.currentBox.backTitle" data={selected} />
      </StyledCurrentPageHeader>

      <StyledImage />

      <StyledHeaderBox>
        <PageHeader sx={pageHeaderStyles} message="pages.vesting.currentBox.title" data={selected} />

        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <VestingBoxPurchaseButton vestingBox={selected} />
          </Grid>
        </Grid>
      </StyledHeaderBox>

      <StyledDescription variant="body2" color="textSecondary" component="div">
        <FormattedMessage id="pages.vesting.currentBox.descriptionTitle" tagName="h4" />
        <RichTextDisplay data={selected.description} />
      </StyledDescription>

      <CurrentBoxContent selected={selected} />

      <Timeline vestingBox={selected} />

    </StyledCurrentRoot>
  );
};

import React, { FC, useMemo, useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { InsertDriveFile } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { ILoadedFile, useDeleteUrl } from "@dzambalaorg/mui-inputs-file-firebase";

import { StyledFileCardRoot, StyledFileIconBox, StyledFileIconButton } from "./styled";
import { imagesFormats } from "../constants";

interface IFileCardProps {
  loadedFile: ILoadedFile;
  onDeleteUrl: (url: File) => void;
}

const getFileDisplayingByType = (type: string | null) => {
  if (!type) return <InsertDriveFile fontSize="large" />;

  if (type.includes("pdf")) {
    return <PictureAsPdfIcon fontSize="large" />;
  } else if (imagesFormats.some(format => type.includes(format))) {
    return null;
  } else {
    return <InsertDriveFile fontSize="large" />;
  }
};

export const FileCard: FC<IFileCardProps> = props => {
  const {
    loadedFile: { url, file },
    onDeleteUrl,
  } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteUrl = useDeleteUrl();

  const displayFileByType = useMemo(() => {
    return getFileDisplayingByType(file.type);
  }, [file]);

  const toggleHover = () => {
    setIsHovered(prevState => !prevState);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    deleteUrl(url).then(() => {
      setIsDeleting(false);
      onDeleteUrl(file);
    });
  };

  return (
    <Tooltip title={file.name}>
      <StyledFileCardRoot
        imageUrl={!displayFileByType ? url : ""}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <StyledFileIconButton
          sx={{
            display: isHovered || isDeleting ? "flex" : "none",
          }}
          onClick={handleDelete}
        >
          {isDeleting ? <CircularProgress size={24} /> : <DeleteForeverIcon />}
        </StyledFileIconButton>
        <StyledFileIconBox
          sx={{
            opacity: isHovered ? 0.5 : 1,
          }}
        >
          {displayFileByType}
        </StyledFileIconBox>
      </StyledFileCardRoot>
    </Tooltip>
  );
};

import { Box, Grid, styled } from "@mui/material";

export const Root = styled("footer")(({ theme }) => ({
  background: "#0B1723",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {},
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTop: "2px solid",
  borderImage: "linear-gradient(90deg, rgba(153, 153, 153, 0.00) 0%, #168897 46.5%, rgba(153, 153, 153, 0.00) 100%) 1",
  width: "100%",
  padding: theme.spacing(3.2, 7, 6, 7),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3.2, 7, 6, 7),
  },
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "748px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "& .MuiGrid-container": {
    margin: theme.spacing(0),
  },

  "& #footer_grid_item_logo": {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  "& #footer_grid_item_form": {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export const StyledCopyrightWrapper = styled(Grid)(({ theme }) => ({
  ...theme.typography.caption,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  opacity: 0.4,
  marginTop: theme.spacing(5.25),
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

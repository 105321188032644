import { FC } from "react";
import { useLocation } from "react-router";
import { Grid, useTheme, useMediaQuery } from "@mui/material";

import { Subscribe } from "./subscribe";
import { PATHS, SocialLinks } from "../../../shared";
import { Root, StyledContent, StyledCopyrightWrapper, StyledWrapper } from "./styled";
import { FooterLogo } from "../../../shared/assets/svg";

export const Footer: FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  if (location.pathname !== PATHS.Main) {
    return null;
  }

  return (
    <Root>
      <StyledWrapper>
        <StyledContent>
          <Grid id={"#footer_grid_container"} container xs={12} spacing={isMd ? 2 : 1}>
            <Grid id={"footer_grid_item_logo"} item xs={isMd ? 12 : 3}>
              <FooterLogo />
              {!isMd && (
                <StyledCopyrightWrapper>
                  ©{new Date().getFullYear()} AlphaX. All Rights Reserved
                </StyledCopyrightWrapper>
              )}
            </Grid>
            <Grid id={"footer_grid_item_form"} item xs={isMd ? 12 : 9}>
              <Subscribe />
              <SocialLinks />
              {isMd && (
                <StyledCopyrightWrapper>
                  ©{new Date().getFullYear()} AlphaX. All Rights Reserved
                </StyledCopyrightWrapper>
              )}
            </Grid>
          </Grid>
        </StyledContent>
      </StyledWrapper>
    </Root>
  );
};

import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { ErrorBoundary } from "@dzambalaorg/mui-page-layout";

import { LandingHeader } from "../landing-header";
import { InnerHeader } from "../inner-header";
import { Footer } from "../footer";
import { Root, StyledContainer } from "./styled";
import { MenuVariant, PATHS } from "../../../shared";

export const Layout: FC = () => {
  const location = useLocation();

  const isInMainPage = location.pathname === PATHS.Main;
  const isPaddingTop = location.pathname !== PATHS.Login;
  const menuVariant = isInMainPage ? MenuVariant.LANDING : MenuVariant.INNER;

  return (
    <Root menuVariant={menuVariant}>
      <InnerHeader />
      <StyledContainer isPaddingTop={isPaddingTop} menuVariant={menuVariant} maxWidth="xl">
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledContainer>
      <Footer />
    </Root>
  );
};

import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";

import { IVestingBox } from "@framework/types";

import { useRenderPlot } from "../../hooks";
import { Root, StyledPlotBox } from "./styled";
import { formatXAxis } from "../../utils";

interface IPlotProps {
  vestingBox: IVestingBox;
  startTimestamp: string;
}

export const Plot: FC<IPlotProps> = props => {
  const { vestingBox, startTimestamp } = props;
  const { formatMessage } = useIntl();

  const { data } = useRenderPlot({
    shape: vestingBox?.shape,
    cliff: vestingBox?.cliff,
    duration: vestingBox?.duration,
    period: vestingBox?.period,
    afterCliffBasisPoints: vestingBox?.afterCliffBasisPoints,
    growthRate: vestingBox?.growthRate,
    startTimestamp,
  });

  return (
    <Root>
      <StyledPlotBox>
        <FormattedMessage id={`enums.shape.${vestingBox.shape}`} tagName="h5" />
        <AreaChart width={800} height={300} data={data}>
          <XAxis
            dataKey="x"
            label={{ position: "insideBottom", offset: 0 }}
            tickFormatter={formatXAxis}
            tick={{ fontSize: 12, fontWeight: "bold" }}
          />
          <YAxis
            domain={[0, 100]}
            label={{ value: "%", position: "insideLeft" }}
            tick={{ fontSize: 14, fontWeight: "bold" }}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="y"
            name={formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })}
            stroke="#71D7F8"
            fill="#4BD4FF"
            dot={false}
          />
        </AreaChart>
      </StyledPlotBox>
      <FormattedMessage id={`pages.vesting.currentBox.aboutVesting.${vestingBox.shape}`} />
    </Root>
  );
};

import { Box, Grid2, styled, SxProps, Typography } from "@mui/material";

export const pageHeaderStyles: SxProps = {
  "& .MuiGrid-item": { margin: 0 },
};

export const StyledContent = styled(Grid2)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const StyledImage = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",
  height: 263,
  backgroundImage: "url(/files/inner/vesting.webp)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  border: "none",
  borderRadius: theme.spacing(1.25),
})) as typeof Box;

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",

  "& .MuiButtonBase-root": {
    padding: theme.spacing(1.5, 2.5),
    borderRadius: theme.spacing(3.75),
    border: "1px solid #285513",
    background: "#285513",
    color: "#FFFFFF !important",
    textAlign: "center",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "135%" /* 24.3px */,

    "&:hover": {

      background: "#448327",
    }
  },
})) as typeof Box;

export const StyledDescription = styled(Typography)(({ theme }) => ({
  "& h4": {
    width: "fit-content",
    color: "#000000",
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    lineHeight: "140%",
    margin: 0,
    borderBottom: "1px solid #4BD4FF",
  },

  "& .DraftEditor-root": {
    marginTop: theme.spacing(1.5),
  },
})) as typeof Typography;

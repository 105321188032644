import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),

  "& h4": {
    width: "fit-content",
    color: "#000000",
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    lineHeight: "140%",
    margin: 0,
    borderBottom: "1px solid #4BD4FF",
  },
})) as typeof Box;

export const StyledDatesItem = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),

  "& h6": {
    width: "fit-content",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "0.4px",
    margin: 0,
  },

  "& .MuiTypography-body1": {
    color: "#1E293B",
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "0.6px",
  },

  "& .DateItem-text": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
})) as typeof Box;

import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

import { IVestingBox } from "@framework/types";
import { StyledAvatar } from "@framework/styled";

import { BoxContentLeftSide, StyledContentRightSide, generateBoxContent } from "../../../../../../shared";
import { StyledContent } from "../styled";
import { StyledContentPrices, StyledContentPricesItem } from "./styled";
import { ReleaseBox } from "./release-box";
import { ProgressBars } from "./progress-bars";

interface ICurrentBoxContent {
  selected: IVestingBox;
}

export const CurrentBoxContent: FC<ICurrentBoxContent> = props => {
  const { selected } = props;

  const { formatMessage } = useIntl();

  const { tokensCount, tokenPrice, boxPrice } = generateBoxContent({
    price: selected.template?.price,
    content: selected.content,
    duration: selected.duration,
    cliff: selected.cliff,
    afterCliffBasisPoints: selected.afterCliffBasisPoints,
  });

  const numericTokensCount = Number(tokensCount.split(" ")[1]);
  const tokenPriceTicker = tokenPrice.split(" ")[0]
  const splitTokenPrice = Number(tokenPrice.split(" ")[1]).toFixed(4);

  return (
    <StyledContent container spacing={2} direction={"row"}>
      <BoxContentLeftSide
        tokenImageUrl={selected.content!.components[0].template!.imageUrl}
        tokensCount={tokensCount}
        tooltipTextId="pages.vesting.currentBox.tokensInfo"
        subtitle={formatMessage({ id: "pages.vesting.currentBox.tokensCount" })}
      />

      <StyledContentRightSide size={{ xs: 6, md: 8, lg: 8 }}>
        <StyledContentPrices>
          <StyledContentPricesItem>
            <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
            <Box className="PricesItem-content">
              <Typography variant="body1" component="p">
                {`${formatMessage({ id: "pages.vesting.currentBox.tokenPrice" })}`}
              </Typography>
              <Typography variant="body2" component="p">
                {`${tokenPriceTicker} ${splitTokenPrice}`}
              </Typography>
            </Box>
          </StyledContentPricesItem>
          <StyledContentPricesItem>
            <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
            <Box className="PricesItem-content">
              <Typography variant="body1" component="p">
                {`${formatMessage({ id: "pages.vesting.currentBox.boxPrice" })}`}
              </Typography>
              <Typography variant="body2" component="p">
                {boxPrice}
              </Typography>
            </Box>
          </StyledContentPricesItem>
        </StyledContentPrices>

        <ProgressBars vestingBox={selected} totalTokensCount={numericTokensCount} />

        <ReleaseBox vestingBox={selected} />
      </StyledContentRightSide>
    </StyledContent>
  );
};

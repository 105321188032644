import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useApiCall } from "@dzambalaorg/react-hooks";
import { IVestingBox } from "@framework/types";

import { PATHS } from "../../../../../shared";

export const useFetchBox = () => {
  const { boxId } = useParams<{ boxId: string }>();
  const [selected, setSelected] = useState<IVestingBox | null>(null);

  const { fn: getContractFn, isLoading } = useApiCall(
    api =>
      api.fetchJson({
        url: `${PATHS.VestingBoxes}/${boxId}`,
      }),
    {
      success: false,
      error: false,
    },
  );

  const getBox = async () => {
    const box = await getContractFn();
    if (box) {
      setSelected(box);
    }
  };

  useEffect(() => {
    void getBox();
  }, []);

  return {
    selected,
    isLoading,
  };
};

import { Box, styled } from "@mui/material";

import { contentSideFlex } from "../../../../../../shared";

export const StyledContentPrices = styled(Box)(({ theme }) => ({
  ...contentSideFlex,
  flexDirection: "row",
  paddingBottom: theme.spacing(1.5),
})) as typeof Box;

export const StyledContentPricesItem = styled(Box)(({ theme }) => ({
  ...contentSideFlex,
  flexDirection: "row",
  gap: theme.spacing(1),
  padding: theme.spacing(1.5, 4),

  "&:not(:last-child)": {
    borderRight: "1px solid #C7C3C3",
  },

  "& .MuiTypography-body1": {
    color: "#000000",
    fontFamily: "Inter",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "0.48px",
  },

  "& .MuiTypography-body2": {
    color: "#000000",
    fontFamily: "Inter",
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "0.48px",
  },

  "& .PricesItem-content": {
    ...contentSideFlex,
    alignItems: "flex-start",
  },
})) as typeof Box;

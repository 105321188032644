import React, { PropsWithChildren } from "react";
import { ButtonOwnProps, useTheme } from "@mui/material";

import { simpleButtonStylesByVariant, StyledButton } from "./styled";

interface ISimpleButtonProps extends ButtonOwnProps {
  id?: string;
  component?: React.ElementType;
  target?: "_blank" | "_self" | "_parent" | "_top";
  buttonVariant?: "primary" | "secondary";

  [key: string]: any;
}

export const SimpleButton = ({
  buttonVariant = "primary",
  children,
  sx,
  ...props
}: PropsWithChildren<ISimpleButtonProps>) => {
  const theme = useTheme();
  return (
    <StyledButton
      sx={{
        ...simpleButtonStylesByVariant(theme)[buttonVariant],
        ...sx,
      }}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

import { useEffect, useState } from "react";
import { BigNumber, Contract, utils } from "ethers";
import { Web3ContextType } from "@web3-react/core";

import { useMetamask } from "@dzambalaorg/react-hooks-eth";
import ReleasableAbi from "@framework/abis/json/ERC721Vesting/releasable.json";

export const useTokenReleasableInfo = (tokenId: string, tokenAddress: string, tokensCount: number) => {
  const [releasable, setReleasable] = useState<number>(0);

  const releasableMetaFn = useMetamask(
    (web3Context: Web3ContextType) => {
      if (!tokenId || !tokenAddress) {
        return Promise.reject();
      }
      const contract = new Contract(tokenAddress, ReleasableAbi, web3Context.provider?.getSigner());
      return contract.releasable(tokenId);
    },
    { success: false, error: false },
  );

  useEffect(() => {
    releasableMetaFn().then(response => {
      /** releasable response has type Array<[tokenType, tokenAddress, tokenId, amount]> **/
      const releasableAmount = response[0 as keyof typeof response][3];
      const formattedValue = Number(utils.formatUnits(BigNumber.from(releasableAmount), 18)).toFixed(4);
      setReleasable(Number(formattedValue));
    });
  }, []);

  const releasablePercentage = ((releasable / tokensCount) * 100).toFixed(4);
  const releasablePercentageForDisplaying = Number(releasablePercentage).toFixed(2);

  return {
    releasable,
    releasablePercentage,
    releasablePercentageForDisplaying
  };
};

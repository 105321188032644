import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

import { PageHeader, ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { useCollection } from "@dzambalaorg/provider-collection";
import { IVestingBox, IVestingBoxSearchDto } from "@framework/types";
import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";

import { PATHS, BoxCard, StyledListRoot } from "../../../../../shared";
import { SimpleButton } from "../../../../../components/buttons/simple";

export const VestingBoxes = () => {
  const { rows, count, search, isLoading, handleChangePage } = useCollection<IVestingBox, IVestingBoxSearchDto>({
    baseUrl: PATHS.VestingBoxes,
  });

  const { formatMessage } = useIntl();

  return (
    <StyledListRoot>
      <PageHeader message="components.header.menu.vesting">
        <SimpleButton component={Link} to={PATHS.VestingApplication}>
          {formatMessage({ id: "pages.vesting.boxes.applyForVesting" })}
        </SimpleButton>
      </PageHeader>

      <ProgressOverlay isLoading={isLoading}>
        <Grid container spacing={2}>
          <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
            {rows.map(vestingBox => (
              <Grid item lg={4} md={6} sm={12} xs={12} key={vestingBox.id}>
                <BoxCard vestingBox={vestingBox} />
              </Grid>
            ))}
          </StyledEmptyWrapper>
        </Grid>
      </ProgressOverlay>

      {!!count && count > search.take && (
        <StyledPagination
          shape="rounded"
          page={search.skip / search.take + 1}
          count={Math.ceil(count / search.take)}
          onChange={handleChangePage}
        />
      )}
    </StyledListRoot>
  );
};

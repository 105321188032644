import { Box, Typography, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "right",
  marginBottom: theme.spacing(4.5),

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  "& span": {
    background: "var(--43, linear-gradient(90deg, #04B6BA 50.09%, #92FCFF 75.06%))",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 500,
  fontSize: theme.spacing(1.75),
}));

export const StyledForm = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: 8,

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
}));

export const StyledEmailWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "174px",
  "& .MuiInputBase-input": {
    color: theme.palette.primary.contrastText,
    height: 18,
    [theme.breakpoints.down("md")]: {
      height: 28,
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: theme.palette.primary.contrastText,
    opacity: 0.6,
  },
  "& .MuiOutlinedInput-root:hover fieldset, .MuiOutlinedInput-root.Mui-focused fieldset": {
    borderColor: "gray",
    borderRadius: theme.spacing(1.5),
  },
  "& fieldset": {
    borderColor: "gray",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  "& p": {
    margin: 0,
  },
}));

export const StyledSubmitWrapper = styled(Box)(({ theme }) => ({
  width: 115,
  "& > .MuiButton-root": {
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #4287f5",
      color: theme.palette.primary.contrastText,
      opacity: 0.6,
    },
  },
  [theme.breakpoints.down("lg")]: {
    flex: 0,
    width: "100%",
    "& > .MuiButton-root": {
      marginTop: 0,
    },
  },
}));

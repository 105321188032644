import { Box, styled } from "@mui/material";

export const TextInputWrapper = styled(Box)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #808080",
      borderRadius: `${theme.spacing(1)} !important`,
      "&:hover": {
        border: "1px solid #E7F0FA",
        background: "rgba(79, 121, 134, 0.10)",
        borderRadius: `${theme.spacing(1)} !important`,
      },
      "&:focus": {
        border: "1px solid #E7F0FA",
        background: "rgba(79, 121, 134, 0.10)",
      },
    },
  },
  "& .MuiInputBase-input": {
    border: "none",
    padding: "12px 16px",
    background: "#0C1824",
    opacity: 0.6,
    borderRadius: theme.spacing(1),
    "&:hover": {
      background: "rgba(79, 121, 134, 0.10)",
    },
    "&:focus": {
      background: "rgba(79, 121, 134, 0.10)",
    },
  },
  "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-webkit-autofill::placeholder":
    {
      border: 0,
      color: "#fff !important",
      fontSize: "inherit !important",
      caretColor: theme.palette.primary.contrastText,
      "-webkit-text-fill-color": theme.palette.primary.contrastText,
      "-webkit-box-shadow": "0 0 0 1000px #0C1824 inset",
      transition: "background-color 5000s ease-in-out 0s",
      background:
        "-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%)",
    },
}));

import { Box, styled } from "@mui/material";
import { contentSideFlex } from "../../../../../../../shared";

export const StyledReleaseBox = styled(Box)(({ theme }) => ({
  ...contentSideFlex,
  width: "70%",
  height: theme.spacing(3),
  flexDirection: "row",
  justifyContent: "flex-start",

  "& .MuiTypography-body1": {
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%" /* 22.4px */,
  },
})) as typeof Box;

export const StyledReleaseBoxSwitcher = styled(Box)(({ theme }) => ({
  ...contentSideFlex,
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(0.3),
  marginLeft: theme.spacing(0.5),

  "& .MuiIconButton-root": {
    padding: 0,

    "& path": {
      fill: "#1B5DA5"
    },

    "&:hover": {
      background: "none",
    }
  }
})) as typeof Box;

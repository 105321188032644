import { FC, PropsWithChildren } from "react";
import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#AEC8E4",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.spacing(1.75),
    fontWeight: 400,
    lineHeight: 1.43,
    color: "#000000",
    backgroundColor: "#FFFFFF",
    border: "1px solid #AEC8E4",
    padding: theme.spacing(1)
  },
}));

export const CustomTooltip: FC<
  PropsWithChildren<{
    title: string;
  }>
> = props => {
  const { title, children } = props;
  return (
    <BootstrapTooltip title={title}>
      <Box sx={{ display: "flex" }}>{children}</Box>
    </BootstrapTooltip>
  );
};

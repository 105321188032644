import React, { SVGProps } from "react";

export const CalendarClose = ({ width = 40, height = 40 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar-close">
        <path
          id="union-1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5002 1.6665C13.4206 1.6665 14.1668 2.4127 14.1668 3.33317V4.99984H25.8335V3.33317C25.8335 2.4127 26.5797 1.6665 27.5002 1.6665C28.4206 1.6665 29.1668 2.4127 29.1668 3.33317V5.04098C33.3779 5.45909 36.6668 9.01205 36.6668 13.3332V28.3332C36.6668 32.9355 32.9359 36.6665 28.3335 36.6665H11.6668C7.06446 36.6665 3.3335 32.9355 3.3335 28.3332V13.3332C3.3335 9.01205 6.62239 5.45909 10.8335 5.04098V3.33317C10.8335 2.4127 11.5797 1.6665 12.5002 1.6665ZM6.95134 11.6665H33.049C32.3626 9.72452 30.5105 8.33317 28.3335 8.33317H11.6668C9.4898 8.33317 7.63773 9.72452 6.95134 11.6665ZM33.3335 14.9998H6.66683V28.3332C6.66683 31.0946 8.90541 33.3332 11.6668 33.3332H28.3335C31.0949 33.3332 33.3335 31.0946 33.3335 28.3332V14.9998ZM15.2861 19.4525C15.937 18.8016 16.9923 18.8016 17.6431 19.4525L20.0002 21.8095L22.3572 19.4525C23.0081 18.8016 24.0633 18.8016 24.7142 19.4525C25.3651 20.1033 25.3651 21.1586 24.7142 21.8095L22.3572 24.1665L24.7142 26.5235C25.3651 27.1744 25.3651 28.2297 24.7142 28.8805C24.0633 29.5314 23.0081 29.5314 22.3572 28.8805L20.0002 26.5235L17.6431 28.8805C16.9923 29.5314 15.937 29.5314 15.2861 28.8805C14.6352 28.2297 14.6352 27.1744 15.2861 26.5235L17.6431 24.1665L15.2861 21.8095C14.6352 21.1586 14.6352 20.1033 15.2861 19.4525Z"
          fill="#4BD4FF"
        />
      </g>
    </svg>
  );
};

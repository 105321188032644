import { secondFromUnixConverter } from "./date";

export function calculateExponentialAvailableTokens(
  totalTokens: number,
  afterCliffBasisPoints: number,
  durationInSeconds: number,
  growthRate: number,
  purchaseDate: Date,
): number {
  const currentTime = new Date();
  const elapsedTime = (currentTime.getTime() - purchaseDate.getTime()) / 1000;
  const preparedGrowthRate = growthRate / 100;

  // Instantly available tokens
  const instantlyAvailableTokens = totalTokens * (afterCliffBasisPoints / 10000);

  // Available tokens taking into account exponential growth
  const unlockableTokens =
    elapsedTime >= durationInSeconds
      ? totalTokens
      : totalTokens * Math.pow(elapsedTime / durationInSeconds, preparedGrowthRate);

  // Percentage of the total available number of tokens, rounded to two decimal places
  return Number((((instantlyAvailableTokens + unlockableTokens) / totalTokens) * 100).toFixed(2));
}

export const setReleasablePercentageDisplaying = (releasablePercentage: number, tge: string) => {
  const currentDateInSeconds = secondFromUnixConverter(new Date());
  const tgeInSeconds = secondFromUnixConverter(new Date(tge));

  if (currentDateInSeconds < tgeInSeconds) {
    return 0;
  } else {
    return releasablePercentage
  }
};

import { FC } from "react";

import { TextInput as GemunionTextInput, ITextInputProps } from "@dzambalaorg/mui-inputs-core";

import { TextInputWrapper } from "./styled";

export const TextInput: FC<ITextInputProps> = props => {
  return (
    <TextInputWrapper>
      <GemunionTextInput variant="outlined" {...props} showLabel={false} />
    </TextInputWrapper>
  );
};

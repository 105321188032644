import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  color: "#F5F5F5",
  borderRadius: theme.spacing(1),
  textTransform: "none",
  border: "1px solid var(--43, #04B6BA)",
  padding: "9px 27px",
  opacity: 0.6,
  background: "#0C1824",
  fontSize: theme.typography.pxToRem(12),
  [theme.breakpoints.down("md")]: {
    height: 52,
  },
  "&:hover": {
    border: "1px solid #4F7986",
    background: "rgba(79, 121, 134, 0.10)",
    boxShadow:
      "1.166px -1.166px 1.166px 0px #168897 inset, -1.166px 1.166px 1.166px 0px rgba(255, 255, 255, 0.42) inset",
  },
  "&:disabled": {
    border: "1px solid var(--43, #04B6BA) !important",
    background: "#0C1824 !important",
  },
}));

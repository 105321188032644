import { FC, Fragment, useState } from "react";
import { Contract } from "ethers";
import { Web3ContextType } from "@web3-react/core";
import CallSplitIcon from "@mui/icons-material/CallSplit";

import { useMetamask } from "@dzambalaorg/react-hooks-eth";
import { ContractFeatures, type IToken } from "@framework/types";
import { ListAction, ListActionVariant } from "@framework/styled";

import VestingSplitABI from "@framework/abis/json/ERC721Vesting/split.json";

import { ISplitDto, SplitDialog } from "./dialog";

interface ISplitTokenButtonProps {
  className?: string;
  disabled?: boolean;
  token: IToken;
  variant?: ListActionVariant;
}

export const SplitTokenButton: FC<ISplitTokenButtonProps> = props => {
  const { className, disabled, variant, token } = props;
  const [isSellDialogOpen, setIsSellDialogOpen] = useState(false);

  const metaSplit = useMetamask(async (values: ISplitDto, web3Context: Web3ContextType) => {
    const { address } = token.template?.contract || {};

    if (!address) {
      throw new Error("unsupported token type");
    }

    const contract = new Contract(address, VestingSplitABI, web3Context.provider?.getSigner());
    return contract.split(Number(token.tokenId), values.percentage) as Promise<void>;
  });

  const handleSplitConfirm = async (values: ISplitDto) => {
    return metaSplit(values).finally(() => {
      setIsSellDialogOpen(false);
    });
  };

  const handleSplit = () => {
    setIsSellDialogOpen(true);
  };

  const handleSplitCancel = () => {
    setIsSellDialogOpen(false);
  };

  return (
    <Fragment>
      <ListAction
        icon={CallSplitIcon}
        onClick={handleSplit}
        message="pages.tokens.current.splitBtn"
        className={className}
        dataTestId="SplitTokenButton"
        disabled={disabled || token.template?.contract?.contractFeatures.includes(ContractFeatures.SOULBOUND)}
        variant={variant}
      />
      <SplitDialog
        onConfirm={handleSplitConfirm}
        onCancel={handleSplitCancel}
        open={isSellDialogOpen}
        initialValues={{
          tokenId: Number(token.tokenId),
          percentage: 0,
        }}
      />
    </Fragment>
  );
};

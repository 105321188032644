import React, { SVGProps } from "react";

export const FooterLogo = ({ width = "56", height = "42" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 42" fill="none">
      <path
        d="M0.0273438 40.9439C0.0439967 40.9106 2.43369 37.6482 5.33685 33.6834L10.613 26.4756H13.4107C15.6395 26.4756 16.1945 26.4867 16.1612 26.5284C16.1418 26.5506 14.5542 28.719 12.6392 31.3345L9.15314 36.0934L13.2609 36.1073C17.1327 36.1128 17.3741 36.1128 17.4352 36.0462C17.4713 36.0046 18.5232 34.5164 19.7722 32.7338C21.0239 30.9541 22.1591 29.3437 22.2978 29.166C24.7042 26.0564 29.256 25.4927 32.3673 27.9333C32.8225 28.2914 33.4331 28.9384 33.805 29.4576C33.9826 29.7019 35.1067 31.2984 36.3057 33.0059L38.4845 36.1128H46.7499L46.6139 35.9268C46.5389 35.8185 45.0096 33.7362 43.225 31.2956C41.4376 28.8551 39.9083 26.7727 39.8334 26.6672L39.6974 26.4728L42.4951 26.4784H45.2844L50.5218 33.639C53.4055 37.5733 55.7924 40.844 55.8368 40.8968L55.9117 40.9967L47.7629 40.9828C42.0426 40.9689 39.5253 40.9495 39.331 40.9217C37.477 40.6247 36.0143 40.0055 34.7209 38.9643C34.2685 38.6061 33.7023 38.0397 33.372 37.6316C33.2416 37.4678 32.1536 35.9379 30.9574 34.2332C29.7611 32.5284 28.7259 31.0735 28.6593 30.9958C28.3012 30.6126 27.6407 30.5959 27.2743 30.9624C27.2188 31.018 26.2252 32.4201 25.0567 34.0749C23.4025 36.4349 22.4033 37.7917 22.0592 38.1452C20.6242 39.6362 18.8812 40.5414 16.8052 40.8773L16.2612 40.9662L8.13176 40.98C1.25133 40.9912 0.00791535 40.9884 0.0356702 40.9384"
        fill="#253039"
      />
      <path
        d="M11.6875 23.5083C11.8013 23.2584 12.4202 21.9368 13.0586 20.5624L14.2132 18.0747L14.8682 18.0803L15.5288 18.0914L16.639 20.4708C17.2523 21.7786 17.8685 23.1002 18.0073 23.4028L18.2682 23.9498H16.3614L15.6287 22.3311C15.2262 21.437 14.8849 20.7096 14.8738 20.7096C14.8599 20.7096 14.524 21.4343 14.1244 22.3227L13.3972 23.9303L12.4369 23.9414L11.4766 23.9498L11.6847 23.5028L11.6875 23.5083Z"
        fill="#7C8389"
      />
      <path
        d="M19.1562 21.0175V18.0771H20.0527H20.9548V20.2567V22.4362H22.2315H23.511V23.197V23.955H21.335H19.1562V21.0175Z"
        fill="#7C8389"
      />
      <path
        d="M27.5591 20.7287C27.7978 20.6232 27.9088 20.4455 27.9088 20.1845C27.9088 20.0818 27.8811 19.9402 27.8478 19.8791C27.7256 19.6375 27.2982 19.5209 26.5599 19.5182H26.188V20.8453L26.7792 20.8287C27.2649 20.812 27.3981 20.7953 27.5535 20.7287M24.3867 21.0175V18.0771H26.0964C27.9671 18.0771 28.0892 18.0883 28.5638 18.3159C29.044 18.5436 29.4242 18.9878 29.588 19.5098C29.6879 19.8263 29.7018 20.3872 29.6268 20.7148C29.4908 21.2729 29.069 21.8088 28.5749 22.0364C28.1225 22.2474 27.9116 22.2863 27.0179 22.303L26.188 22.3224V23.9522H24.3895V21.0147L24.3867 21.0175Z"
        fill="#7C8389"
      />
      <path
        d="M30.7461 21.0175V18.0771H31.6259H32.5058V19.1766V20.2761H33.566H34.6262V19.1766V18.0771H35.5061H36.3859V21.0175V23.955H35.5061H34.6262V22.7972V21.6366H33.566H32.5058V22.7972V23.955H31.6259H30.7461V21.0175Z"
        fill="#7C8389"
      />
      <path
        d="M37.3047 23.9297C37.3047 23.9102 37.9125 22.5886 38.6591 20.9977L40.0052 18.0962L40.6658 18.0851L41.3236 18.0796L42.6253 20.8644C43.3358 22.3998 43.952 23.7187 43.9936 23.8103L44.063 23.9602H42.1618L41.4291 22.3415C41.0238 21.4475 40.6852 20.72 40.6713 20.72C40.6602 20.72 40.3244 21.4447 39.9247 22.3332L39.2003 23.9408L38.2567 23.9519C37.7321 23.9574 37.3075 23.9463 37.3075 23.9324"
        fill="#7C8389"
      />
      <path
        d="M27.2941 15.5505C25.3097 15.3645 23.5 14.3733 22.3066 12.8323C22.165 12.6491 21.0243 11.0415 19.7726 9.2562C18.5264 7.47647 17.4745 5.98549 17.4356 5.94384C17.3745 5.87998 17.1331 5.8772 13.2585 5.88276L9.15355 5.89386L12.6368 10.6556C14.5574 13.2766 16.1422 15.4395 16.1617 15.4672C16.195 15.5033 15.6399 15.5144 13.4111 15.5144H10.6135L5.3095 8.26221L0 1.01833L7.64646 1.00723C15.379 0.993344 16.2005 1.00723 16.9721 1.1405C18.4376 1.39038 19.7753 1.96789 20.9521 2.85082C21.4434 3.2201 22.1567 3.91422 22.548 4.39455C22.7174 4.60557 23.822 6.15763 25.0071 7.84574C26.195 9.53663 27.2109 10.9665 27.2664 11.0221C27.6244 11.3774 28.2295 11.3858 28.5931 11.0415C28.6763 10.9637 29.7171 9.51164 30.9078 7.81242C32.0929 6.11876 33.1782 4.58891 33.3169 4.41677C34.6408 2.72866 36.7058 1.52366 38.9234 1.13772C39.695 1.00723 40.5193 0.993344 48.2463 1.00723L55.8927 1.01833L50.6138 8.21779C47.7162 12.1771 45.3265 15.4422 45.3126 15.47C45.2876 15.5089 44.6964 15.5172 42.4927 15.5089L39.7061 15.4978L43.2032 10.7222C45.1266 8.09285 46.7003 5.92996 46.7003 5.91052C46.7003 5.89386 44.8519 5.8772 42.5954 5.8772H38.4849L36.3061 8.9841C33.4668 13.0267 33.5584 12.9073 33.0671 13.4126C32.1873 14.3205 31.1437 14.948 29.9281 15.3006C29.1537 15.5228 28.0796 15.6283 27.2941 15.5505Z"
        fill="#253039"
      />
    </svg>
  );
};

import { FC } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { enqueueSnackbar } from "notistack";

import { NewsletterSubscriptionType } from "@framework/types";
import { FormWrapper } from "@dzambalaorg/mui-form";
import { useApiCall } from "@dzambalaorg/react-hooks";

import { TextInput } from "../../../inputs/text";
import { SubmitButton } from "../../../buttons/submit";
import { StyledBold } from "../../styled";
import { validationSchema } from "./schema";
import { Root, StyledEmailWrapper, StyledForm, StyledHeader, StyledSubmitWrapper } from "./styled";

export const Subscribe: FC = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const { formatMessage } = useIntl();

  const { fn: subscribe } = useApiCall(
    (api, data: any) => {
      return api
        .fetchJson({
          url: "/newsletter/subscribe",
          method: "POST",
          data,
        })
        .then(() => {
          enqueueSnackbar(formatMessage({ id: "snackbar.subscriptionSuccess" }), { variant: "success" });
        });
    },
    { success: false, error: false },
  );

  const onSubmit = async (values: any, form: any) => {
    await subscribe(form, values);
  };

  return (
    <Root>
      <FormWrapper
        initialValues={{
          email: "",
          type: NewsletterSubscriptionType.MARKETING_NEWSLETTER,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        showButtons={false}
        showPrompt={false}
        sx={{
          "& .MuiFormControl-root": {
            width: "100%",
            marginTop: "0px",
            marginBottom: "0px",
          },
        }}
      >
        <Stack
          width={"100%"}
          display={"flex"}
          flexDirection={isMd ? "column" : "row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"20px"}
        >
          <StyledHeader>
            <FormattedMessage
              id="pages.landing.subscribe.title"
              values={{ b: chunks => <StyledBold>{chunks}</StyledBold> }}
            />
          </StyledHeader>
          <StyledForm>
            <StyledEmailWrapper>
              <TextInput name="email" label="email" />
            </StyledEmailWrapper>
            <StyledSubmitWrapper>
              <SubmitButton />
            </StyledSubmitWrapper>
          </StyledForm>
        </Stack>
      </FormWrapper>
    </Root>
  );
};

import React, { FC, useEffect, useState } from "react";
import { BigNumber, Contract, utils } from "ethers";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { Web3ContextType } from "@web3-react/core";
import { Box, IconButton, Typography } from "@mui/material";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { useMetamask } from "@dzambalaorg/react-hooks-eth";
import { ListActionVariant } from "@framework/styled";
import WithdrawnAmountsAbi from "@framework/abis/json/ERC721Vesting/getWithdrawnAmounts.json";

import {
  BoxContentLeftSide,
  Plot,
  progressBarColors,
  StyledContentRightSide,
  StyledCurrentPageHeader,
  StyledCurrentRoot,
  StyledLinearProgress,
  StyledProgressBarBox,
  generateBoxContent,
  useTokenReleasableInfo,
} from "../../../../../shared";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";
import { ButtonBack } from "../../../../../shared/assets/svg/button-back";
import { VestingBoxReleaseButton } from "../../../../../components/buttons/release";
import { SplitTokenButton } from "../../../../../components/buttons/split";
import { StyledContent } from "./styled";
import { ISelected } from "./useFetchToken";

interface ICurrentBoxProps {
  selected: ISelected;
}

export const CurrentBox: FC<ICurrentBoxProps> = props => {
  const { selected } = props;
  const { template, tokenId } = selected;
  const { box } = template;

  const { progressOrange, progressBlue, progressGreen } = progressBarColors;
  const [balance, setBalance] = useState<string>("0");

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const withdrawAmountMetaFn = useMetamask(
    (web3Context: Web3ContextType) => {
      if (!template) {
        return Promise.reject();
      }
      const tokenAddress = template.contract!.address;
      const contract = new Contract(tokenAddress, WithdrawnAmountsAbi, web3Context.provider?.getSigner());
      return contract.getWithdrawnAmounts(tokenId);
    },
    { success: false, error: false },
  );

  useEffect(() => {
    if (template) {
      withdrawAmountMetaFn().then(response => {
        /** getWithdrawnAmounts response has type Array<[tokenType, tokenAddress, tokenId, amount]> **/
        const withdrawAmount = response[0 as keyof typeof response][3];
        const formattedValue = Number(utils.formatUnits(BigNumber.from(withdrawAmount), 18)).toFixed(4);
        setBalance(formattedValue);
      });
    }
  }, []);

  const goBackHandler = () => navigate(-1);

  const { tokensCount } = generateBoxContent({
    price: template.price,
    content: template.box.content,
    duration: box.duration,
    cliff: box.cliff,
    afterCliffBasisPoints: box.afterCliffBasisPoints,
  });

  const tokenValue = tokensCount.split(" ")[0];
  const tokenCount = Number(tokensCount.split(" ")[1]);
  const remainingTokensProgress = ((tokenCount - Number(balance)) * 100) / tokenCount;
  const withdrawnTokensProgress = (Number(balance) * 100) / tokenCount;

  const { releasablePercentage, releasablePercentageForDisplaying } = useTokenReleasableInfo(
    tokenId,
    template!.contract!.address,
    tokenCount,
  );

  return (
    <StyledCurrentRoot>
      <Breadcrumbs paths={["tokens", "tokens.current"]} data={[{}, { title: box.title }]} />

      <StyledCurrentPageHeader>
        <IconButton onClick={goBackHandler}>
          <ButtonBack />
        </IconButton>
        <PageHeader message="pages.tokens.current.title" data={{ title: box.title }} />
      </StyledCurrentPageHeader>

      <Plot vestingBox={template.box} startTimestamp={new Date(template.createdAt).toDateString()} />

      <StyledContent container spacing={2} direction={"row"}>
        <BoxContentLeftSide
          tokenImageUrl={template.box.content!.components[0].template!.imageUrl || ""}
          tokensCount={balance}
          subtitle={formatMessage(
            { id: "pages.tokens.current.contentSubtitle" },
            { token: tokenValue, value: tokenCount },
          )}
          tooltipTextId="pages.tokens.current.balanceTooltip"
        />

        <StyledContentRightSide size={{ xs: 6, md: 8, lg: 8 }}>
          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.tokensInBox" },
                {
                  token: tokenValue,
                  value: tokenCount - Number(balance),
                },
              )}`}
            </Typography>
            <StyledLinearProgress
              {...progressOrange}
              content={`${remainingTokensProgress}%` as never}
              variant="determinate"
              value={remainingTokensProgress}
            />
          </StyledProgressBarBox>
          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.withdrawn" },
                {
                  token: tokenValue,
                  value: balance,
                },
              )}`}
            </Typography>
            <StyledLinearProgress
              {...progressBlue}
              content={`${withdrawnTokensProgress}%` as never}
              variant="determinate"
              value={withdrawnTokensProgress}
            />
          </StyledProgressBarBox>
          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.releasable" },
                {
                  token: tokenValue,
                  value: releasablePercentage,
                },
              )}`}
            </Typography>
            <StyledLinearProgress
              {...progressGreen}
              content={`${releasablePercentageForDisplaying}%` as never}
              variant="determinate"
              value={Number(releasablePercentage)}
            />
          </StyledProgressBarBox>

          <Box className="CurrentToken-Buttons-box">
            <VestingBoxReleaseButton
              className="CurrentToken-releaseBtn"
              token={selected}
              variant={ListActionVariant.button}
            />
            <SplitTokenButton className="CurrentToken-splitBtn" token={selected} variant={ListActionVariant.button} />
          </Box>
        </StyledContentRightSide>
      </StyledContent>

      {/*<CurrentBoxPiePlot />*/}

      {/*<StyledContent>*/}
      {/*  <StyledPlotBox>*/}
      {/*    <StyledPlotContent>*/}

      {/*      <CurrentBoxContent*/}
      {/*        title="pages.tokens.current.infoTitle"*/}
      {/*        selected={{*/}
      {/*          price: template.price,*/}
      {/*          content: box.content,*/}
      {/*          startTimestamp: box.startTimestamp,*/}
      {/*          duration: box.duration,*/}
      {/*          cliff: box.cliff,*/}
      {/*          afterCliffBasisPoints: box.afterCliffBasisPoints,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </StyledPlotContent>*/}

      {/*    <Button variant="outlined" onClick={handleShapeButtonClick}>*/}
      {/*      {formatMessage({ id: `pages.tokens.current.shape.${selected.template.box.shape}` })}*/}
      {/*    </Button>*/}

      {/*    <CurrentBoxActions token={selected} />*/}
      {/*  </StyledPlotBox>*/}
      {/*</StyledContent>*/}

      {/*<Typography variant="body2" color="textSecondary" component="div">*/}
      {/*  <RichTextDisplay data={selected.template.box.description} />*/}
      {/*</Typography>*/}

      {/*<Dialog*/}
      {/*  fullWidth*/}
      {/*  maxWidth={"lg"}*/}
      {/*  aria-labelledby="shape-dialog-title"*/}
      {/*  aria-describedby="shape-dialog-content"*/}
      {/*  open={isDialogOpen}*/}
      {/*  onClose={() => setIsDialogOpen(false)}*/}
      {/*>*/}
      {/*  <DialogTitle*/}
      {/*    id="shape-dialog-title"*/}
      {/*    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}*/}
      {/*  >*/}
      {/*    <FormattedMessage id={`pages.tokens.current.shape.${selected.template.box.shape}`} />*/}
      {/*  </DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*    <Plot vestingBox={selected.template.box} />*/}
      {/*  </DialogContent>*/}
      {/*</Dialog>*/}
    </StyledCurrentRoot>
  );
};

import { Box, Grid2, styled } from "@mui/material";

export const StyledContent = styled(Grid2)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const StyledPlotBox = styled(Box)(({ theme }) => ({
  position: "relative",
  flexBasis: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& .MuiButtonBase-root": {
    alignSelf: "flex-start",
  },

  "& .MuiSpeedDial-root": {
    height: 36.5,
    bottom: "0px !important",
    right: "0px !important",

    "& .MuiFab-sizeLarge": {
      width: 36.5,
      height: 36.5,
    },

    "& .MuiFab-root": {
      color: "rgb(25, 118, 210)",
      border: "1px solid rgb(25, 118, 210)",
      background: "transparent",
    },

    "& .MuiSpeedDialAction-fab": {
      width: "fit-content",
      height: "fit-content",
      border: "none",
      borderRadius: theme.spacing(0.5),
      background: "transparent",
    },
  },
})) as typeof Box;

export const StyledPlotContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexWrap: "wrap",

  "& .MuiPaper-root": {
    flexBasis: "35%",
  },

  "& .recharts-wrapper": {
    flexBasis: "65%",
  },
})) as typeof Box;

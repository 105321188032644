import { Box, Container, styled, ContainerProps, BoxProps, SxProps } from "@mui/material";

import { MenuVariant } from "../../../shared";

interface IRoot {
  menuVariant: MenuVariant;
}
interface IStyledContainer extends IRoot {
  isPaddingTop: boolean;
}

export const Root = styled(Box, { shouldForwardProp: prop => prop !== "isInMainPage" })<BoxProps & IRoot>(
  ({ menuVariant }) => ({
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
    background: menuVariant === MenuVariant.LANDING ? "#0B1723" : "#F2F2F2",
  }),
);

const setStylesByMenuVariant = (theme: any, isPaddingTop?: boolean): { [Key in MenuVariant]: SxProps } => ({
  [MenuVariant.LANDING]: {
    paddingTop: isPaddingTop ? theme.spacing(16) : 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(7.5),
    },
    "&.MuiContainer-root": {
      maxWidth: 2200,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  [MenuVariant.INNER]: {
    maxWidth: "80%",
    padding: theme.spacing(11, 2, 2, 2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
});

export const StyledContainer = styled(Container, { shouldForwardProp: prop => prop !== "isPaddingTop" })<
  ContainerProps & IStyledContainer
>(({ theme, isPaddingTop, menuVariant }) => ({
  ...setStylesByMenuVariant(theme, isPaddingTop)[menuVariant],
}));

import { FC } from "react";

import { FormDialog } from "@dzambalaorg/mui-dialog-form";
import { NumberInput } from "@dzambalaorg/mui-inputs-core";

import { validationSchema } from "./validation";

export interface ISplitDto {
  tokenId: number;
  percentage: number;
}

export interface ISplitDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: ISplitDto, form?: any) => Promise<void>;
  initialValues: ISplitDto;
}

export const SplitDialog: FC<ISplitDialogProps> = props => {
  const { initialValues, ...rest } = props;
  return (
    <FormDialog
      initialValues={initialValues}
      validationSchema={validationSchema}
      message="dialogs.splitToken"
      testId="SplitDialogForm"
      {...rest}
    >
      <NumberInput name="percentage" required />
    </FormDialog>
  );
};

import { Stepper, styled } from "@mui/material";

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepLabel-root": {
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  "& .Mui-active": {
    fill: "#ffd70e",

    "& .MuiStepIcon-text": {
      fill: "#000000",
    },
  },

  "& .Mui-completed": {
    fill: "#285513",

    "& .MuiStepIcon-text": {
      fill: "#000000",
    },
  },
}));

import { Box, styled } from "@mui/material";

import { pageHeader } from "../../../../../shared";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& h2": {
    ...pageHeader(theme),
  },

  "& h4": {
    ...pageHeader(theme),
    margin: 0,
    color: "#6C6C6C",
    fontSize: theme.spacing(2.75),
    fontWeight: 500,
    lineHeight: "140%" /* 16.8px */,
  },
}));

import { SxProps } from "@mui/material";
import { StylesScheme } from "../types";

export const buttonStyles: SxProps = {
  color: "#F5F5F5",
  textAlign: "center",
  fontSize: "14px",
  fontWeight: 500,
  padding: "12px 8px 12px 8px",
  borderRadius: "8px",
  background: "rgba(79, 121, 134, 0.10)",

  "&:hover": {
    background: "rgba(79, 121, 134, 0.30)",
  },
};

export const firebaseLoginContainerStyles: SxProps = {
  "& .FirebaseLogin-buttonsBox": {
    "& button": {
      ...buttonStyles,
    },
  },
};

export const doubleBorderColorBox: SxProps = {
  border: "1px solid #4F7986",
  background: "rgba(79, 121, 134, 0.10)",
  boxShadow: "1px -1px 1px 0px #168897 inset, -1px 1px 1px 0px rgba(255, 255, 255, 0.42) inset",
  backdropFilter: "blur(10px)",
};

export const dashboardMenuItem = (theme: any, isActive?: boolean): SxProps => ({
  textAlign: "center",
  display: "flex",
  alignItem: "center",
  gap: theme.spacing(1.25),
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textDecoration: "none",
  cursor: "pointer",
  color: isActive ? "#71FFFF" : theme.palette.text.primary,

  "& #fill": {
    fill: isActive ? "#71FFFF" : theme.palette.text.primary,
  },
  "& #stroke": {
    stroke: isActive ? "#71FFFF" : theme.palette.text.primary,
  },

  "&:hover": {
    color: "#71FFFF",
    "& #fill": {
      fill: "#71FFFF",
    },
    "& #stroke": {
      stroke: "#71FFFF",
    },
  },
  [theme.breakpoints.down("md")]: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(20),
  },
});

export const cardBasic: StylesScheme = {
  background: "linear-gradient(151deg, rgba(12, 71, 79, 0.12) -1.66%, rgba(12, 101, 104, 0.12) 81.87%)",
  boxShadow: "0px 0px 11px 0px rgba(21, 174, 184, 0.34) inset, 0px 0px 3px 0px rgba(255, 255, 255, 0.50) inset",
  backdropFilter: "blur(22px)",
};

export const pageHeader = (theme: any): StylesScheme => ({
  fontSize: theme.spacing(4),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.12px",
  color: "#000000",
});

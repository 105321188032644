import React, { SVGProps } from "react";

export const CalendarCheck = ({ width = 40, height = 40 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar-check">
        <path
          id="union-1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5002 1.6665C13.4206 1.6665 14.1668 2.4127 14.1668 3.33317V4.99984H25.8335V3.33317C25.8335 2.4127 26.5797 1.6665 27.5002 1.6665C28.4206 1.6665 29.1668 2.4127 29.1668 3.33317V5.04098C33.3779 5.45909 36.6668 9.01205 36.6668 13.3332V28.3332C36.6668 32.9355 32.9359 36.6665 28.3335 36.6665H11.6668C7.06446 36.6665 3.3335 32.9355 3.3335 28.3332V13.3332C3.3335 9.01205 6.62239 5.45909 10.8335 5.04098V3.33317C10.8335 2.4127 11.5797 1.6665 12.5002 1.6665ZM6.95134 11.6665H33.049C32.3626 9.72452 30.5105 8.33317 28.3335 8.33317H11.6668C9.4898 8.33317 7.63773 9.72452 6.95134 11.6665ZM33.3335 14.9998H6.66683V28.3332C6.66683 31.0946 8.90541 33.3332 11.6668 33.3332H28.3335C31.0949 33.3332 33.3335 31.0946 33.3335 28.3332V14.9998ZM27.8453 17.988C28.4962 18.6389 28.4962 19.6941 27.8453 20.345L19.512 28.6783C18.8611 29.3292 17.8059 29.3292 17.155 28.6783L12.9883 24.5117C12.3374 23.8608 12.3374 22.8055 12.9883 22.1547C13.6392 21.5038 14.6945 21.5038 15.3453 22.1547L18.3335 25.1428L25.4883 17.988C26.1392 17.3371 27.1945 17.3371 27.8453 17.988Z"
          fill="#4BD4FF"
        />
      </g>
    </svg>
  );
};

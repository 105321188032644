import React, { FC, useState } from "react";
import { CardHeader } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { ITemplate, IToken, IVestingBox } from "@framework/types";

import { StyledCardMedia } from "../styled";
import { generateBoxContent } from "../../utils";
import { PATHS } from "../../constants";
import { useTokenReleasableInfo } from "../../hooks";
import { Root, StyledCardActionArea, StyledCardActions, StyledCardActionsInfo } from "./styled";

interface IBoxCard {
  template: ITemplate & { box: IVestingBox };
  token: IToken;
}

export const TokenCard: FC<IBoxCard> = props => {
  const { template, token } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const location = useLocation();
  const { formatMessage } = useIntl();

  const { tokensCount, tge } = generateBoxContent({
    price: template!.box.template?.price,
    content: template!.box.content,
    duration: template!.box.duration,
    cliff: template!.box.cliff,
    afterCliffBasisPoints: template!.box.afterCliffBasisPoints,
  });

  const numericTokensCount = Number(tokensCount.split(" ")[1]);

  const { releasablePercentageForDisplaying } = useTokenReleasableInfo(
    token.tokenId,
    token.template!.contract!.address,
    numericTokensCount,
  );

  const path = location.pathname === PATHS.VestingBoxes ? PATHS.CurrentBox : PATHS.Token;

  return (
    <Root
      component={Link}
      to={`${path.replace(":tokenId", `${token?.id}`)}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCardActionArea
        sx={{
          transform: isHovered ? "scale(1.04)" : "scale(1.0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <StyledCardMedia className="BoxCard-media" component="img" image={"/files/inner/vesting.webp"} />
      </StyledCardActionArea>
      <StyledCardActions>
        <CardHeader title={template!.title} />
        <StyledCardActionsInfo sx={{ paddingTop: 1.25 }}>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.vesting.currentBox.tge" })}</span>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.tokens.releasable" })}</span>
        </StyledCardActionsInfo>
        <StyledCardActionsInfo>
          <span className="CardTopInfoItem-bold">{tge}</span>
          <span className="CardTopInfoItem-green">{`${releasablePercentageForDisplaying}%`}</span>
        </StyledCardActionsInfo>
      </StyledCardActions>
    </Root>
  );
};

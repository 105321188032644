import { FC } from "react";
import { AppBar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { NetworkButton, WalletButton } from "@dzambalaorg/provider-wallet";
import { MetamaskLoginButton } from "@dzambalaorg/login-button-metamask";
import { ParticleLoginButton } from "@dzambalaorg/login-button-particle";
import { WalletConnectLoginButton } from "@dzambalaorg/login-button-wallet-connect";
import { FirebaseLogin } from "@dzambalaorg/firebase-login";

import { ConnectSvg } from "../../../shared";
import { VestingBoxesButton } from "./dashboard";
import { Sections } from "./sections";
import { StyledGrow, StyledLink, StyledToolbar } from "./styled";

export const InnerHeader: FC = () => {
  return (
    <AppBar position="fixed">
      <StyledToolbar>
        <StyledLink component={RouterLink} to="/">
          <img src="/files/inner/full_logo.png" alt="logo" width={128} height={46} />
        </StyledLink>
        <StyledGrow />
        <NetworkButton />
        <WalletButton ConnectIcon={ConnectSvg}>
          <FirebaseLogin
            withEmail={false}
            buttons={[MetamaskLoginButton, WalletConnectLoginButton, ParticleLoginButton]}
          />
        </WalletButton>
        <VestingBoxesButton />
        <Sections />
      </StyledToolbar>
    </AppBar>
  );
};

import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

import { StyledAvatar } from "@framework/styled";

import { InfoSvg } from "../../assets";
import { CustomTooltip } from "../tooltip";
import { StyledContentLeftSide } from "../styled";

interface IBoxContentLeftSideProps {
  tokenImageUrl: string;
  tokensCount: string;
  subtitle: string;
  tooltipTextId?: string;
}

export const BoxContentLeftSide: FC<IBoxContentLeftSideProps> = props => {
  const { tokensCount, tokenImageUrl, tooltipTextId, subtitle } = props;

  const { formatMessage } = useIntl();

  return (
    <StyledContentLeftSide size={{ xs: 6, md: 4, lg: 4 }}>
      <StyledAvatar src={tokenImageUrl} width={150} height={150} />
      <Typography variant="body1" component="p">
        {tokensCount}
      </Typography>
      <Box className="LeftSide-InfoBox">
        <Typography variant="body2" component="p">
          {subtitle}
        </Typography>
        {tooltipTextId && (
          <CustomTooltip title={formatMessage({ id: tooltipTextId })}>
            <InfoSvg />
          </CustomTooltip>
        )}
      </Box>
    </StyledContentLeftSide>
  );
};

import { object, number } from "yup";

export const validationSchema = object().shape({
  tokenId: number()
    .required("form.validations.valueMissing")
    .integer("form.validations.badInput")
    .min(0, "form.validations.rangeUnderflow"),
  percentage: number()
    .required("form.validations.valueMissing")
    .typeError("form.validations.badInput")
    .min(0, "form.validations.rangeUnderflow")
    .max(100, "form.validations.rangeOverflow"),
});

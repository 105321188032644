import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 2, 1.5, 0),
  border: "2px solid #AEC8E4",
  borderRadius: theme.spacing(1.25),

  "& h4": {
    margin: 0,
    paddingLeft: theme.spacing(2),
    color: "#000000",
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    lineHeight: "140%"
  },

  "& h5": {
    margin: 0,
    paddingLeft: theme.spacing(2),
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    lineHeight: "140%"
  },

  "& .recharts-surface": {
    width: "50%",
  },

  "& .recharts-layer": {

    "& .recharts-curve": {
      stroke: "#57D5FD !important",
    },

    "& .recharts-active-dot": {
      "& path": {
        stroke: "#71D7F8",
      },
    },
  },
})) as typeof Box;


export const StyledPlotBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing(2),
})) as typeof Box;

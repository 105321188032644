import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box, SvgIcon, Typography } from "@mui/material";

import { IVestingBox } from "@framework/types";
import {
  CalendarCheck,
  CalendarClose,
  CalendarDay,
  CalendarRemove,
  Clock,
  generateBoxContent,
} from "../../../../../../../shared";
import { Root, StyledDatesItem } from "./styled";

interface ITimelineDatesProps {
  vestingBox: IVestingBox;
}

const timelineDataIcons = {
  ido: CalendarDay,
  cliffEnds: CalendarRemove,
  tge: CalendarCheck,
  end: CalendarClose,
  duration: Clock,
}

export const TimelineDates: FC<ITimelineDatesProps> = props => {
  const { vestingBox } = props;

  const { tge, ido, end, cliffEnds, duration } = generateBoxContent({
    price: vestingBox.template?.price,
    content: vestingBox.content,
    duration: vestingBox.duration,
    cliff: vestingBox.cliff,
    afterCliffBasisPoints: vestingBox.afterCliffBasisPoints,
  });

  const timelineData = Object.entries({ ido, cliffEnds, tge, end, duration }).map(([key, value]) => {
    if (key === "cliffEnds" && !vestingBox.cliff) return null;

    return {
      id: `pages.vesting.currentBox.${key}`,
      value,
      icon: timelineDataIcons[key as keyof typeof timelineDataIcons]
    };
  });

  return (
    <Root>
      {timelineData.map(dataItem => {
        if (!dataItem) return null;

        return <StyledDatesItem key={dataItem.id}>
          <SvgIcon component={dataItem.icon} />
          <Box className="DateItem-text">
            <FormattedMessage id={dataItem.id} tagName="h6" />
            <Typography variant="body1" component="p">
              {dataItem.value}
            </Typography>
          </Box>
        </StyledDatesItem>
      })}
    </Root>
  );
};
